<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row v-if="!examinationRoomId">
              <b-col cols="5">
                <b-row v-if="!examinationRoomId">
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="1.Chương trình đào tạo/Bồi dưỡng:"
                      label-for="trainingSystemSelected"
                    >
                      <v-select
                        v-model="trainingSystemSelected"
                        :options="trainingSystems"
                        :reduce="(option) => option.value"
                        :disabled="!!examinationRoomId"
                        :clearable="false"
                        placeholder="1.Chọn chương trình đào tạo/Bồi dưỡng"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="2.Khóa - Học kỳ:"
                      label-for="coursesSemestersSelected"
                    >
                      <v-select
                        v-model="coursesSemestersSelected"
                        :options="coursesSemestersOptions"
                        :reduce="(option) => option.value"
                        :disabled="!!examinationRoomId"
                        :clearable="false"
                        placeholder="2.Chọn khóa - học kỳ"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row v-if="!examinationRoomId">
                  <b-col cols="12">
                    <b-form-group
                      label-cols="2"
                      label-cols-lg="2"
                      label="3.Học phần:"
                      label-for="subjectsSelected"
                    >
                      <v-select
                        v-model="subjectsSelected"
                        :options="subjectsOptions"
                        :reduce="(option) => option.value"
                        :disabled="!!examinationRoomId"
                        :clearable="false"
                        placeholder="3.Chọn học phần"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="2"
                      label-cols-lg="2"
                      label="4.Phòng thi:"
                      label-for="examinationRoomSubjectsSelected"
                    >
                      <v-select
                        v-model="examinationRoomSubjectsSelected"
                        :options="examinationRoomSubjectsOptions"
                        :reduce="(option) => option.value"
                        :disabled="!!examinationRoomId"
                        :clearable="false"
                        placeholder="4.Chọn phòng thi"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <validation-observer
              ref="studentExaminationPointFormRef"
              #default="{invalid}"
            >
              <b-row>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-show="filter.examination_room_id !== 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-right: 5px"
                      @click="onSelectStudentExaminationRoom"
                    >
                      <span class="text-nowrap text-right">Danh sách phách</span>
                    </b-button>
                    <b-button
                      v-show="codeByExaminationRooms.length > 0 && filter.examination_room_id !== 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :disabled="invalid"
                      @click="onSave(codeByExaminationRooms)"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="CheckIcon" /> Lưu lại
                      </span>
                    </b-button>
                    <b-dropdown
                      v-show="codeByExaminationRooms.length > 0 && filter.examination_room_id !== 0"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      text="Nhập file"
                      right
                      variant="outline-primary"
                    >
                      <b-dropdown-item @click="downloadFile">
                        <span class="text-nowrap text-right">
                          <feather-icon icon="DownloadIcon" /> Tải về file mẫu
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="$refs.inputFileRef.$el.click()">
                        <span class="text-nowrap text-right">
                          <feather-icon icon="UploadIcon" /> Tải lên file
                        </span>
                      </b-dropdown-item>
                      <b-form-file
                        ref="inputFileRef"
                        accept=".xls, .xlsx"
                        :hidden="true"
                        plain
                        @change="importExcel"
                      />
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="codeByExaminationRooms"
                    :pagination-options="paginationOptions"
                    :line-numbers="true"
                    class="my-table"
                    style-class="my-table vgt-table striped bordered"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'score'">
                        <validation-provider
                          #default="{ errors }"
                          rules="required|between:0,10"
                          name="Điểm"
                        >
                          <b-form-input
                            v-model="props.row.score"
                            :tabindex="props.row.originalIndex + 1"
                            @change="
                              changeCell(
                                props.row.score,
                                props.column.field,
                                props.row.originalIndex
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </span>
                      <span v-else-if="props.column.field === 'note'">
                        <b-form-input
                          v-model="props.row.note"
                          type="text"
                          @change="
                            changeCell(
                              props.row.note,
                              props.column.field,
                              props.row.originalIndex
                            )
                          "
                        />
                      </span>
                      <!-- Column: Common -->
                      <span v-else>{{
                        props.formattedRow[props.column.field]
                      }}</span>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BOverlay,
  BRow,
  BFormInput,
  BFormFile,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { between, required } from '@core/utils/validations/validations'
import { getUser } from '@/auth/utils'
import * as XLSX from 'xlsx'

export default {
  name: 'StudentExaminationPoint',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    VueGoodTable,
    vSelect,
    BFormInput,
    BFormFile,
    BDropdown,
    BDropdownItem,
  },
  props: {
    examinationRoomId: {
      type: Number,
      default: null,
    },
    trainingSystemId: {
      type: Number,
      default: null,
    },
    courseSemesterId: {
      type: Number,
      default: null,
    },
    subjectId: {
      type: Number,
      default: null,
    },
    subjectCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      trainingSystemSelected: undefined,
      coursesSemestersSelected: undefined,
      examinationRoomSubjectsSelected: undefined,
      subjectsSelected: undefined,
      isLoading: false,
      filter: {
        organizationId: getUser().orgId,
        examination_room_id: 0,
      },
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'Số phách',
          field: 'headerCode',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm bằng số',
          field: 'score',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'note',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      studentExaminationRoomsByHeaderCode: [],
      required,
      between,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'dropdown/trainingSystems',
      codeByExaminationRooms: 'studentExaminationRoom/codeByExaminationRooms',
      examinationRoomSubjects: 'studentExaminationRoom/examinationRoomSubjects',
      coursesSemesters: 'studentExaminationRoom/coursesSemesters',
      subjects: 'studentExaminationRoom/subjects',
    }),
    coursesSemestersOptions() {
      return this.coursesSemesters.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    examinationRoomSubjectsOptions() {
      return this.examinationRoomSubjects.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    subjectsOptions() {
      return this.subjects.map(item => ({
        value: item.code,
        label: item.name,
      }))
    },
    examinationPointUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.EXAMINATION_POINT)
    },
    selectedSubjectId() {
      return this.subjects.find(e => e.code === this.subjectsSelected).id || 0
    },
    isSeparateRoom() {
      return this.subjectsSelected.includes('_THCLASS_') ? 1 : 0
    },
  },
  watch: {
    async trainingSystemSelected(val) {
      this.coursesSemestersSelected = null
      this.examinationRoomSubjectsSelected = null
      this.subjectsSelected = null
      if (!val) {
        this.reset()
        this.$store.commit('studentExaminationRoom/SET_COURSESSEMESTERS', {
          data: [],
        })
        this.$store.commit(
          'studentExaminationRoom/SET_EXAMINATIONROOMSUBJECTS',
          { data: [] },
        )
        this.$store.commit('studentExaminationRoom/SET_SUBJECT', { data: [] })
      } else {
        await this.getCoursesSemesters({
          organizationId: getUser().orgId,
          training_system_id: this.trainingSystemSelected,
        })
        if (this.courseSemesterId) {
          const found = this.coursesSemesters.find(element => +element.id === +this.courseSemesterId)
          if (found) this.coursesSemestersSelected = this.courseSemesterId
        }
      }
    },
    async coursesSemestersSelected(val) {
      this.examinationRoomSubjectsSelected = null
      if (!val) {
        this.reset()
        this.$store.commit('studentExaminationRoom/SET_SUBJECT', { data: [] })
        this.$store.commit(
          'studentExaminationRoom/SET_EXAMINATIONROOMSUBJECTS',
          { data: [] },
        )
      } else {
        await this.getSubjects({
          organizationId: getUser().orgId,
          courses_semester_id: this.coursesSemestersSelected,
          hasExam: 1,
        })
        // if (this.subjectId) {
        //   const found = this.subjects.find(element => +element.id === this.subjectId)
        //   if (found) {
        //     this.subjectsSelected = this.subjectId
        //   }
        // }
        if (this.subjectCode) {
          const found = this.subjects.find(e => e.code === this.subjectCode)
          if (found) {
            this.subjectsSelected = this.subjectCode
          }
        }
      }
    },
    async subjectsSelected(val) {
      this.examinationRoomSubjectsSelected = null
      if (!val) {
        this.reset()
        this.$store.commit(
          'studentExaminationRoom/SET_EXAMINATIONROOMSUBJECTS',
          { data: [] },
        )
      } else {
        await this.getExaminationRoomSubjects({
          organizationId: getUser().orgId,
          courses_semester_id: this.coursesSemestersSelected,
          subject_id: this.selectedSubjectId,
          is_separate: this.isSeparateRoom,
        })
        if (this.examinationRoomId) {
          const found = this.examinationRoomSubjects.find(element => +element.id === this.examinationRoomId)
          if (found) this.examinationRoomSubjectsSelected = this.examinationRoomId
        }
      }
    },
    async examinationRoomSubjectsSelected(val) {
      if (!val) {
        this.reset()
      } else {
        this.$refs.studentExaminationPointFormRef.reset()
        this.filter.examination_room_id = this.examinationRoomSubjectsSelected
        await this.getCodeByExaminationRooms(this.filter)
      }
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getTrainingSystems({ organizationId: getUser().orgId })
      if (this.trainingSystemId) {
        this.trainingSystemSelected = this.trainingSystemId
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCodeByExaminationRooms:
        'studentExaminationRoom/getCodeByExaminationRooms',
      getCoursesSemesters: 'studentExaminationRoom/getCoursesSemesters',
      getSubjects: 'studentExaminationRoom/getSubjects',
      getExaminationRoomSubjects:
        'studentExaminationRoom/getExaminationRoomSubjects',
      saveScoresByCode: 'studentExaminationRoom/saveScoresByCode',
      exportPdfScoreHeaderCode: 'studentExaminationRoom/exportPdfScoreHeaderCode',
    }),
    // eslint-disable-next-line no-undef
    ...mapMutations({
      setMainData: 'studentExaminationRoom/SET_CODE_BY_EXAMINATION_ROOMS',
    }),
    reset() {
      this.filter.examination_room_id = 0
      this.$store.commit(
        'studentExaminationRoom/SET_CODE_BY_EXAMINATION_ROOMS',
        { data: [] },
      )
    },
    async onSelectStudentExaminationRoom() {
      this.isLoading = true
      try {
        await this.getCodeByExaminationRooms(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    changeCell(data, column, row) {
      this.codeByExaminationRooms[row][column] = data
    },
    async onSave(data) {
      if (!this.examinationPointUpdatable) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bạn không có quyền nhập điểm thi!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.score === null || element.score === undefined)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đầy đủ dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => isNaN(element.score))) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đúng kiểu dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.score < 0 || element.score > 10)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Điểm chỉ được nằm trong khoảng từ 0 đến 10!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      const scoresByCode = data.map(element => ({
        id: element.id,
        score: element.score,
        note: element.note || null,
        examinationRoomId: parseInt(this.filter.examination_room_id, 10),
        studentCreditClassId: element.studentCreditClassId,
      }))
      const creditClassType = this.subjects.find(e => e.code === this.subjectsSelected).type || null
      try {
        const response = await this.saveScoresByCode({
          scoresByCode,
          courseSemesterId: this.coursesSemestersSelected,
          subjectId: this.selectedSubjectId,
          type: 1,
          creditClassType,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await Promise.all([
              this.getCodeByExaminationRooms(this.filter),
              this.exportPdfFile(),
            ])
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfFile() {
      try {
        const data = await this.exportPdfScoreHeaderCode({
          examinationRoomId: this.examinationRoomSubjectsSelected,
          score: 1, // Hiện điểm
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    downloadFile() {
      let data = []
      if (this.codeByExaminationRooms.length > 0) {
        data = this.codeByExaminationRooms.map(element => ({
          MA_PHACH: element.headerCode,
          DIEM: element.score,
          GHI_CHU: element.note,
        }))
      }
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'scores')
      XLSX.writeFile(wb, 'NhapDiemThiTheoPhach.xlsx')
    },
    importExcel(e) {
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File dữ liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File đầu vào phải có kiểu định dạng xlsx hoặc xls',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const fileReader = new FileReader()
        fileReader.addEventListener(
          'load',
          async ev => {
            const { result } = ev.target
            const workbook = XLSX.read(result, { type: 'binary' })
            const wsName = workbook.SheetNames[0]
            const data = XLSX.utils.sheet_to_json(workbook.Sheets[wsName])
            if (data.length > 0) {
              const dataMerge = this.codeByExaminationRooms.map(item => {
                const found = data.find(bItem => bItem.MA_PHACH === item.headerCode)
                return { ...item, ...{ score: found?.DIEM || null, note: found?.GHI_CHU || null } }
              })
              this.setMainData({ data: dataMerge })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'File không có dữ liệu',
                  icon: 'InfoIcon',
                  variant: 'warning',
                },
              })
            }
            this.resetInputFile()
          },
          false,
        )
        fileReader.readAsBinaryString(files[0])
      } catch (ex) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${ex.code}] ${ex.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
